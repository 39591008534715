import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';

import Overlay from 'ol/Overlay';
import View from 'ol/View';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {OSM, TileJSON, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Interaction} from "ol/interaction";
import {defaults as defaultInteractions} from 'ol/interaction.js';

const map_element = document.querySelector('#ol_map');
const data = JSON.parse(map_element.dataset.geojson);
const linkAttribute = map_element.dataset.linkAttribute;
const titleAttribute = map_element.dataset.titleAttribute;
const descriptionAttribute = map_element.dataset.descriptionAttribute;

const mapTileUrl = map_element.dataset.mapTileUrl;


const strokeStyle = new Stroke({
  color: 'rgba(242,152,74, 1)',
  width: 3
});

const fillStyle = new Fill({
  color: 'rgba(242, 152, 74, 0.67)',
});

const pointStyle =new CircleStyle({
  radius: 5,
  fill: fillStyle,
  stroke: strokeStyle,
});

const customStyle = new Style({
  stroke: strokeStyle,
  fill: fillStyle,
  image: pointStyle,
});

var styles = {
  'Point': customStyle,
  'LineString': customStyle,
  'MultiLineString': customStyle,
  'MultiPoint': customStyle,
  'MultiPolygon': customStyle,
  'Polygon': customStyle,
  'GeometryCollection': customStyle,
  'Circle': customStyle,
};


var highlightStyle = new Style({
  fill: new Fill({
    color: 'rgba(242, 152, 74, 1)',
  }),
  stroke: new Stroke({
    color: 'white',
    width: 3,
  }),
  image:new CircleStyle({
    radius: 5,
    fill:  new Fill({
      color: 'rgba(242, 152, 74, 1)',
    }),
    stroke: strokeStyle,
  }),
});

var styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};

var vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(data, {
    featureProjection: 'EPSG:3857'
  }),
});
var vectorLayer = new VectorLayer({
  source: vectorSource,
  style: styleFunction,
});

var source = new TileJSON({
  url: mapTileUrl,
  tileSize: 256,
  crossOrigin: 'anonymous'
});

var interactions = defaultInteractions({
  onFocusOnly: true,
  doubleClickZoom: false,
  mouseWheelZoom: false,
  altShiftDragRotate:false,
  pinchRotate:false
});

var map = new Map({
  interactions: interactions,
  layers: [
    new TileLayer({
      source: source
    }),
    vectorLayer
  ],
  target: 'ol_map',
  view: new View({
    center: [0, 0],
    zoom: 2,
  }),
});
map.getView().fit(vectorSource.getExtent(), {padding: [40,40,40,40]});


var selected = null;


const hoverElement = document.getElementById('ol-hover');

var popup = new Overlay({
  element: hoverElement,
  stopEvent: false
});
map.addOverlay(popup);

map.on('singleclick', function(e) {
  map.forEachFeatureAtPixel(e.pixel, function (f) {
    if(f.values_[linkAttribute] != undefined) {
      window.open(f.values_[linkAttribute], '_self');
    }
  });
});


map.on('pointermove', function (e) {
  hoverElement.innerHTML = '';


  if (selected !== null) {
    selected.setStyle(undefined);
    map.getViewport().style.cursor = '';
  }

  map.forEachFeatureAtPixel(e.pixel, function (f) {
    var coordinate = e.coordinate;
    popup.setPosition(coordinate);
    var inner = '<div>';
    if (f.values_[titleAttribute] != undefined) {
      inner += '<div class="title">' + f.values_[titleAttribute] + '</div>';
    }
    if (f.values_[descriptionAttribute] != undefined) {
      inner += '<div class="description">' + f.values_[descriptionAttribute] + '</div>';
    }
    inner += '</div>';
    hoverElement.innerHTML = inner;
    if (f.values_[linkAttribute] != undefined) {
      map.getViewport().style.cursor = 'pointer';
    }
    selected = f;
    f.setStyle(highlightStyle);
    return true;
  });

});
